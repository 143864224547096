import Api from "@/Api/Api";
import DatabaseResponse from "@/Api/Database/Response/DatabaseResponse";

class DatabaseApi extends Api {
    list(serverId: string): Promise<any> {
        return this.get<DatabaseResponse[]>("/servers/" + serverId + "/databases");
    }
}

export default new DatabaseApi();
