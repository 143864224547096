






































import {Component, Vue} from "vue-property-decorator";
import MenuStore from "@/Store/Modules/MenuStore";
import {getModule} from "vuex-module-decorators";
import DatabaseResponse from "@/Api/Database/Response/DatabaseResponse";
import databaseApi from "@/Api/Database/DatabaseApi";

@Component
export default class Databases extends Vue {
    menuStore: MenuStore = getModule(MenuStore);

    loading: boolean = true;
    sortBy: string = 'fat';
    sortDesc: boolean = false;
    headers: any[] =  [
        {
            text: '',
            align: 'start',
            value: 'status',
            width: '20px'
        },
        {
            text: this.$t('database.database'),
            value: 'database',
        },
        {
            text: this.$t('database.associatedUsers'),
            value: 'users'
        },
        {
            text: this.$t('createdAt'),
            value: 'created_at'
        },
        {
            text: this.$t('actions'),
            value: 'actions',
            align: 'end',
        },
    ];
    databases: DatabaseResponse[] = [];

    mounted() {
        this.getDatabases();
    }

    getDatabases() {
        databaseApi.list(this.$route.params['id']).then((response) => {
            this.databases = response.data as DatabaseResponse[];
            this.loading = false;
        })
    }
}
