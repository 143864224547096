var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.databases,"items-per-page":-1,"hide-default-footer":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 0)?_c('v-badge',{staticClass:"pulse",attrs:{"color":"grey","inline":"","dot":""}}):_vm._e(),(item.status === 1)?_c('v-badge',{attrs:{"color":"green","inline":"","dot":""}}):_vm._e()]}},{key:"item.users",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.users.length)+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at))+" ")]}},{key:"item.actions",fn:function(){return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-dots-vertical")])]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_vm._v("Kaas")])],1)],1)],1)]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }