


























import {Component, Vue} from "vue-property-decorator";
import MenuStore from "@/Store/Modules/MenuStore";
import {getModule} from "vuex-module-decorators";
import databaseUserApi from "@/Api/Database/DatabaseUserApi";
import DatabaseUserResponse from "@/Api/Database/Response/DatabaseUserResponse";

@Component
export default class Users extends Vue {
    menuStore: MenuStore = getModule(MenuStore);

    loading: boolean = true;
    sortBy: string = 'fat';
    sortDesc: boolean = false;
    headers: any[] =  [
        {
            text: '',
            align: 'start',
            value: 'status',
            width: '20px'
        },
        {
            text: this.$t('user'),
            value: 'user',
        },
        {
            text: this.$t('host'),
            value: 'host',
        },
        {
            text: this.$t('database.associatedDatabases'),
            value: 'databases'
        },
        {
            text: this.$t('createdAt'),
            value: 'created_at'
        },
    ];
    databases: DatabaseUserResponse[] = [];

    mounted() {
        this.getDatabaseUsers();
    }

    getDatabaseUsers() {
        databaseUserApi.list(this.$route.params['id']).then((response) => {
            this.databases = response.data as DatabaseUserResponse[];
            this.loading = false;
        })
    }
}
