import Api from "@/Api/Api";
import DatabaseUserResponse from "@/Api/Database/Response/DatabaseUserResponse";

class DatabaseUserApi extends Api {
    list(serverId: string): Promise<any> {
        return this.get<DatabaseUserResponse[]>("/servers/" + serverId + "/database_users");
    }
}

export default new DatabaseUserApi();
