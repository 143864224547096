


















































import {Component, Vue} from "vue-property-decorator";
import SiteStore from "@/Store/Modules/SiteStore";
import {getModule} from "vuex-module-decorators";
import siteApi from "@/Api/Site/SiteApi";

@Component
export default class Settings extends Vue {
    deleteDialog: boolean = false;
    deleteSiteText: string|null = null;
    siteStore: SiteStore = getModule(SiteStore);

    deleteSite() {
        if (this.deleteSiteText !== this.siteStore.getSite?.website) {
            return;
        }

        siteApi.deleteSite(this.$route.params['id'] as string).then(() => {
            this.$router.push('/servers/' + this.siteStore.getSite?.server?.id);
        }).catch(() => {
            this.$router.push('/servers/' + this.siteStore.getSite?.server?.id);
        });
    }
}
