








import {Component, Vue} from "vue-property-decorator";
// import MenuStore from "@/Store/Modules/MenuStore";
// import {getModule} from "vuex-module-decorators";
// import SitesResponse from "@/Api/Site/Response/SitesResponse";
// import siteApi from "@/Api/Site/SiteApi";
// import ServerResponse from "@/Api/Server/Response/ServerResponse";

@Component
export default class ServerIndex extends Vue {

}
