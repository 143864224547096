import { render, staticRenderFns } from "./Users.vue?vue&type=template&id=51f32ad2&"
import script from "./Users.vue?vue&type=script&lang=ts&"
export * from "./Users.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VBadge,VDataTable})
