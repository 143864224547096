import {VuexModule, Module, Mutation, Action} from 'vuex-module-decorators';
import store from '../Index';
import ServerResponse from "@/Api/Server/Response/ServerResponse";
import SiteResponse from "@/Api/Site/Response/SiteResponse";

interface ButtonInterface {
    name: string,
    path?: string,
    modal?: boolean,
    color?: string,
    icon?: string,
}

@Module({
    name: 'site',
    store: store,
    dynamic: true
})
class SiteStore extends VuexModule {
    site: SiteResponse|null = null;

    @Mutation
    updateSite(server: SiteResponse|null): void {
        this.site = server;
    }

    @Action
    setSite(server: SiteResponse|null) {
        this.context.commit('updateSite', server);
    }

    get getSite(): SiteResponse|null {
        return this.site;
    }
}
export default SiteStore;

