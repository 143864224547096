








































































































import {Component, Vue, Watch} from "vue-property-decorator";
import MenuStore from "@/Store/Modules/MenuStore";
import {getModule} from "vuex-module-decorators";
import SitesResponse from "@/Api/Site/Response/SitesResponse";
import siteApi from "@/Api/Site/SiteApi";
import SiteResponse from "@/Api/Site/Response/SiteResponse";
import PaginateObject from "@/Api/PaginateObject";
import SiteCreateRequest from "@/Api/Site/Request/SiteCreateRequest";
import {ValidationObserver} from "vee-validate";
import ServerStore from "@/Store/Modules/ServerStore";
import ServerResponse from "@/Api/Server/Response/ServerResponse";

@Component
export default class Sites extends Vue {
    menuStore: MenuStore = getModule(MenuStore);
    serverStore: ServerStore = getModule(ServerStore);
    $refs!: {
        form: InstanceType<typeof ValidationObserver>;
    };
    serverId: string|null = null;
    sortBy: string = localStorage.getItem('sites-sort-by') ?? '';
    sortDesc: boolean = localStorage.getItem('sites-sort-desc') === "true";
    loading: boolean = true;

    totalItems: number = 0;
    options: any = {};
    headers: object = [
        {
            text: '',
            align: 'start',
            value: 'status',
            width: '20px'
        },
        {
            text: this.$t('site.siteName'),
            align: 'start',
            value: 'website',
        },
        {
            text: this.$t('createdAt'),
            value: 'created_at'
        }
    ];
    sites: SitesResponse = {
        data: [],
        links: {first: "", last: "", next: "", prev: ""},
        meta: {current_page: 0, from: 0, last_page: 0, links: [], per_page: 0, to: 0, total: 0}
    };

    createLoading: boolean = false;
    createValid: boolean = false;
    createSite: SiteCreateRequest = {
        website: "",
        php_version: "8.0",
        user: "depcloud",
        website_path: "/"
    };

    // @TODO: Replace with server php-versions
    phpVersions: any[] = [
        {
            name: this.$t('site.doNotUsePHP'),
            value: null
        }
    ];

    async mounted() {
        this.serverId = this.$route.params['id'];

        this.menuStore.addButtons([{
            name: (this.$t('site.create') as string),
            modal: 'modal-create-site'
        }]);

        window.Echo.private('server.' + this.serverId)
            .listen('.site.created',() => {
                this.getSites();
            }).listen('.site.deleted',() => {
                this.getSites();
            });
        await this.getSites();
    }

    get getServer(): ServerResponse|null {
        return this.serverStore.getServer;
    }

    @Watch('getServer')
    getServerChanged() {
        const serverFeatures = this.getServer?.features;
        serverFeatures?.forEach((feature) => {
            if (feature.feature === "php" && feature.installed) {
                this.phpVersions.push({
                    name: "PHP " + feature.version,
                    value: feature.version,
                });
            }
        });
    }

    getSites() {
        this.loading = true;

        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        const paginate: PaginateObject = {
            page: page,
            per_page: itemsPerPage,
            sort_by: sortBy,
            sort_desc: sortDesc,
        }
        siteApi.list(this.serverId, paginate).then((response) => {
            this.sites = response.data as SitesResponse;
            this.totalItems = this.sites.meta.total;
            this.loading = false;

            this.menuStore.addSubtitle(this.$tc('site.sitesCount', this.sites.data?.length, {count: this.sites.data?.length}) as string);
        });
    }

    @Watch('options')
    handler() {
        this.getSites();
    }

    sortByUpdate(type: string)
    {
        if (type === undefined) {
            localStorage.removeItem('sites-sort-by');
        } else {
            localStorage.setItem('sites-sort-by', type);
        }
    }

    sortDescUpdate(desc: boolean)
    {
        if (desc === undefined) {
            localStorage.removeItem('sites-sort-desc');
        } else {
            localStorage.setItem('sites-sort-desc', String(desc));
        }
    }

    createSiteRequest() {
        this.createLoading = true;
        this.$refs.form.validate().then((valid) => {
            if (!valid) {
                this.createLoading = false;
                return;
            }

            siteApi.create(this.serverId as string, this.createSite)
                .then(() => {
                    this.createLoading = false;
                    this.menuStore.closeModal();
                    this.getSites();

                    this.createSite = {
                        website: "",
                        php_version: "8.0",
                        user: "depcloud",
                        website_path: "/"
                    };
                })
                .catch((error: any) => {
                    this.createLoading = false;
                    this.$refs.form.setErrors(error.response.data.errors);
                });
        });
    }

    handleClick(site: SiteResponse)
    {
        this.$router.push('/sites/' + site.id);
    }

    beforeDestroy() {
        window.Echo.private('server.' + this.serverId)
            .stopListening('.site.created')
            .stopListening('.site.deleted');
        this.menuStore.clear();
    }

    get createSiteModal() {
        return this.menuStore.currentModal === "modal-create-site";
    }

    set createSiteModal(value: boolean) {
        this.menuStore.closeModal();
    }
}
